<template>
  <div>
    <div style="padding: 64px 80px 0;width:793px;height:1123px;background-color:#fff;line-height: 1;" v-for="(item, i) in tabletList" :key="i">
      <h3 style="margin-bottom:1.5em;font-size: 58px;text-align: center">{{showName}}</h3>
      <div style="font-size: 24px;font-family:SimSun;">
        <div style="display: flex">佛事种类：<span style="min-width: 6em" :style="underlineStyle">{{typeName}}</span>*</div>
        <min-row-text style="margin: 1.5em 0 3em;" line-height="2em" indent="3em" :line="1" :underline-style="underlineStyle" v-if="item.user_type == 2">
          名称：<span>{{item.name || (item.personList && item.personList.length > 0  ? item.personList[0].name : '')}}</span>*
        </min-row-text>
        <template v-else>
          <div style="margin-top: 1.5em" v-if="item.hasRelation">
            <buddhism-person-list :underline-style="underlineStyle" :list="item.personArr" v-if="item.personArr && item.personArr.length > 0"/>
            <buddhism-person-list :underline-style="underlineStyle" :list="item.otherArr" v-if="item.otherArr && item.otherArr.length > 0"/>
            <min-row-text style="margin-top: 1.5em;" line-height="2em" :line="2" :underline-style="underlineStyle" v-if="item.specialPerson">
              {{ item.specialPerson }}
            </min-row-text>
            <div style="display:flex;align-items:flex-start;margin-top: 1.5em;">
              <span>阳上：</span>
              <div style="flex:1;display:flex;flex-wrap:wrap;gap: 1em 0;min-width:0;">
                <div style="display:flex;width: 50%;box-sizing: border-box;" :style="{paddingLeft: i % 2 == 1 ? '0.5em' : 0}" v-for="(p, i) in item.posterityArr || []" :key="i">
                  <span style="min-width: 1em;">{{p.relation}}</span>
                  <span :style="{flex: 1, marginLeft: '0.5em', minWidth: 0, ...underlineStyle}">{{p.list[0] || ""}}</span>
                  <span>, </span>
                  <span :style="{flex: 1, minWidth: 0, ...underlineStyle}">{{p.list[1] || ""}}</span>
                  <span>* </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div style="display: flex;margin-top: 1.5em;" v-for="(p, j) in item.personArr || []" :key="j">
              姓名：
              <span :style="{flex: 1, minWidth: 0, ...underlineStyle}">{{ p.name }}</span>
              *生日
              <span :style="{width: '6em', ...underlineStyle, textAlign: 'center'}">{{p.birth ? p.birth.year : ""}}</span>
              年
              <span :style="{width: '3em', ...underlineStyle, textAlign: 'center'}">{{p.birth ? p.birth.month : ""}}</span>
              月
              <span :style="{width: '3em', ...underlineStyle, textAlign: 'center'}">{{p.birth ? p.birth.date : ""}}</span>
              日
              <span :style="{width: '3em', ...underlineStyle, textAlign: 'center'}">{{p.timeText}}</span>
              时(农历)*
            </div>
          </div>
        </template>
        <min-row-text style="margin-top: 1.5em;" line-height="2em" indent="3em" :line="3" :underline-style="underlineStyle">
          地址：<span>{{item.showAddr}}</span>*
        </min-row-text>
        <div style="display: flex;margin-top: 1.5em;">
          佛事日期：
          <span :style="{flex: 1, minWidth: 0, ...underlineStyle, textAlign: 'center'}">{{date.date.year}}</span>
          年
          <span :style="{width: '4em', ...underlineStyle, textAlign: 'center'}">{{date.date.month}}</span>
          月
          <span :style="{width: '4em', ...underlineStyle, textAlign: 'center'}">{{date.date.date}}</span>
          日 (农历：
          <span :style="{width: '4em', ...underlineStyle, textAlign: 'center'}">{{date.lunar.lunarMonth}}</span>
          月
          <span :style="{width: '4em', ...underlineStyle, textAlign: 'center'}">{{Math.round(date.lunar.lunarDate)}}</span>
          日)*
        </div>
        <div style="display: flex;margin-top: 1.5em;">
          <div style="flex: 1;display: flex;">
            联系人 ：<span :style="{flex: 1, minWidth: 0, ...underlineStyle}">{{item.contact_person}}</span>*
          </div>
          <div style="flex: 1;display: flex;margin-left: 1em;">
            联系电话 ：<span :style="{flex: 1, minWidth: 0, ...underlineStyle}">{{item.tel || ""}}</span>*
          </div>
        </div>
        <div style="display: flex;align-items: flex-start;margin-top: 1.5em;">
          <span>付款情况：</span>
          <div style="border-top:1px solid #000;border-left:1px solid #000;font-size: 12px;">
            <div style="display: flex">
              <span :style="priceNumStyle" v-for="(h, n) in priceHeader" :key="n">{{h}}</span>
            </div>
            <div style="display: flex">
              <span :style="priceNumStyle" v-for="(h, n) in item.priceNumArr" :key="n">{{h}}</span>
            </div>
          </div>
          <span>（{{orderStatus}}）*</span>
        </div>
        <div style="display: flex;margin-top: 2em;">经手人：<span :style="{width: '6em',...underlineStyle}">{{info.evaluateUser ? info.evaluateUser.fm || info.evaluateUser.name : ""}}</span>*</div>
      </div>
    </div>
  </div>
</template>

<script>
    import {getLunarDay} from "../../components/calendar/date";
    import {getBuddhismRelationText, getTimeText, hasRelation} from "../../common/buddha/buddhism";
    import {numberToText} from "../../common/js/tool";
    import {getTabletPrice} from "../../common/buddha/type";
    import BuddhismPersonList from "./BuddhismPersonList";
    import MinRowText from "./MinRowText";

    export default {
        name: "BuddhismRegistrationInfo",
        props: {
            info: Object
        },
        components: {
            BuddhismPersonList,
            MinRowText
        },
        data() {
            return {
                subWidth: '15%',
                textWidth: '35%',
                subTitleStyle: {
                    fontWeight: 600,
                    fontSize: '18px'
                },
                underlineStyle: {
                    borderBottom: "1px solid #000",
                    whiteSpace: 'nowrap'
                },
                priceNumStyle: {
                    width: "1.5em",
                    height: "2em",
                    borderRight: "1px solid #000",
                    borderBottom: "1px solid #000",
                    textAlign: "center",
                    lineHeight: "2em"
                },
                priceHeader: "仟佰拾万仟佰拾元角分".split("")
            }
        },
        computed: {
            showName() {
                const info = this.info;
                let res = "";
                if(info) {
                    if(info.type) {
                        res = ["01", "03", "05"].indexOf(info.type) >= 0 ? "吉祥佛事" : "往生佛事";
                    } else {
                        res = info.tempName;
                    }
                }
                return res;
            },
            typeName() {
                const info = this.info;
                let res = "";
                if(info) {
                    let typeName = info.typeName;
                    switch (typeName) {
                        case "单独延生普佛":
                        case "单独往生普佛":
                            typeName = "单独普佛";
                            break;
                        case "众信延生普佛":
                        case "众信往生普佛":
                            typeName = "众信普佛";
                            break;
                        case "焰口一大士":
                            typeName = "焰口";
                            break;
                        case "焰口三大士":
                            typeName = "三大士焰口";
                            break;
                    }
                    res = info.scripture || info.chanting || typeName;
                }
                return res;
            },
            priceText() {
                let res = '';
                if(this.info && this.info.price) {
                    res = numberToText(this.info.price / 100)
                }
                return res;
            },
            date() {
                let res = {
                    date: {},
                    lunar: {}
                }
                if(this.info?.date) {
                    const date = new Date(this.info.date);
                    res = {
                        date: {
                            year: date.getFullYear(),
                            month: date.getMonth() + 1,
                            date: date.getDate()
                        },
                        lunar: getLunarDay(date)
                    }
                }
                return res;
            },
            orderStatus() {
                let res = "";
                if(this.info?.order) {
                    switch (this.info.order.status) {
                        case 0:
                        case 1:
                        case 6:
                        case 8:
                            res = "预定";
                            break;
                        case 2:
                        case 5:
                        case 7:
                            res = "已付";
                            break;
                        case 3:
                        case 4:
                            res = "取消";
                            break;
                    }
                }
                return res;
            },
            tabletList() {
                let res = [];
                if(this.info) {
                    let temp = this.info.temp;
                    let tablet = this.info.tablet;
                    //往生
                    if(tablet && tablet.length > 0) {
                        res = tablet.map((item, i) => {
                            let table = [];
                            item.hasRelation = hasRelation({cat: temp.cat, type: item.type});
                            // 往生佛事
                            if(item.hasRelation) {
                                item.relation = getBuddhismRelationText(item.relation);
                                item.personArr = [{relationPrefix: "先"}, {relationPrefix: "先"}];
                                if(item.relation == '氏门中历代宗亲') {
                                    item.specialPerson = `${item.personList && item.personList.length > 0 ? item.personList[0].name : ''}氏门中历代宗亲`;
                                } else if(['普利十方','冤亲债主','堕胎婴灵'].indexOf(item.relation) >= 0) {
                                    item.specialPerson = item.relation;
                                } else {
                                    const custom = item.relation === "自定义牌位"; // 是否是自定义牌位
                                    const personArr = item.personList.map(p => {
                                        const res = {
                                            ...p,
                                            birth: p.birth ? this.getShowDateInfo(p.birth, p.birthDateType) : {},
                                            die: p.die ? this.getShowDateInfo(p.die, p.dieDateType) : {},
                                            birthTimeText: this.getShowTime(p.birthTime || p.time),
                                            dieTimeText: this.getShowTime(p.dieTime),
                                        };
                                        // 不是自定义牌位 将关系第一个字提取出来
                                        if(!custom) {
                                            res.relationPrefix = res.relation.slice(0,1);
                                            res.relationSuffix = res.relation.slice(1);
                                        }
                                        return res;
                                    });
                                    // 以先开头的关系放在前面 否则前面空着 牌位放后面
                                    if(item.relation.startsWith("先")) {
                                        item.personArr = personArr;
                                    } else {
                                        item.otherArr = personArr;
                                    }
                                }
                                const posterityArr = [
                                    { relation: "子", list: [] },
                                    { relation: "女", list: [] },
                                ]
                                if(item.posterityList && item.posterityList.length > 0) {
                                    item.posterityList.forEach(p => {
                                        const index = posterityArr.findIndex(r => r.relation == p.relation || r.relation === "");
                                        if(index >= 0) {
                                            const row = posterityArr[index];
                                            // 每行两个名字 不满则放进当前行
                                            if(row.list.length < 2) {
                                                row.list.push(p.name);
                                            } else {
                                                // 当前下标是最后一个 先插入一个空白行
                                                if(index == posterityArr.length - 1) {
                                                    posterityArr.push({ relation: "", list: [] })
                                                }
                                                // 间隔插入到数组（每行两个，保持对齐）
                                                posterityArr.splice(index + 2, 0, { relation: p.relation, list: [p.name] });
                                            }
                                        } else {
                                            posterityArr.push({ relation: p.relation, list: [p.name] });
                                        }
                                    });
                                }
                                // 保持数组长度为偶数
                                if(posterityArr.length % 2 == 1) {
                                    posterityArr.push({ relation: "", list: [] });
                                }
                                if(posterityArr.length < 6) {
                                    posterityArr.push(...new Array(6 - posterityArr.length).fill({ relation: "", list: [] }));
                                }
                                item.posterityArr = posterityArr;
                            } else {
                                let personArr = [];
                                if(item.personList && item.personList.length > 0){
                                    personArr = item.personList.map(p => {
                                        return  {
                                            ...p,
                                            birth: p.birth ? this.getShowDateInfo(p.birth, p.birthDateType) : {},
                                            timeText: this.getShowTime(p.time)
                                        }
                                    });
                                }
                                if(personArr.length < 5) {
                                    personArr.push(...new Array(5 - personArr.length).fill({}));
                                }
                                item.personArr = personArr;
                            }
                            if(!item.price) {
                                item.price = getTabletPrice(item, i, this.info.temp, this.info.time_range) * 100;
                            }
                            const arr = String(item.price).split("");
                            if(arr.length < this.priceHeader.length) {
                                arr.unshift("¥");
                                arr.unshift(...new Array(this.priceHeader.length - arr.length).fill(""))
                            }
                            return {
                                ...item,
                                table,
                                showAddr: (item.area || []).join("") + item.address,
                                contact_person: item.contact_person,
                                tel: item.tel,
                                priceNumArr: arr
                            }
                        });
                    }
                }
                return res;
            }
        },
        methods: {
            getShowTime(time) {
                let res = "";
                if(time) {
                    res = time == "unknown" ? "吉时" : getTimeText(time).match(/[\u4e00-\u9fa5]/g).join("");
                }
                return res.replace("时", "");
            },
            getShowDateInfo(date, type) {
                const res = {
                    year: "",
                    month: "",
                    date: ""
                };
                date = new Date(date);
                if (type == 2) {
                    date = getLunarDay(date);
                    res.year = `${date.ganzhiYear}(${date.lunarYear})`;
                    res.month = date.lnongMonth;
                    res.date = date.lnongDate;
                } else {
                    res.year = date.getFullYear();
                    res.month = date.getMonth() + 1;
                    res.date = date.getDate();
                }
                return res;
            }
        }
        // mounted() {
        //     let iframe = this.$el.querySelector("iframe");
        //     console.log(iframe.style.width);
        // }
    }
</script>

<style lang="less">
</style>
