<template>
    <div class="content md flex-box vertical buddhism-detail" v-if="info">
        <buddha-detail class="buddha-detail-area" ref="buddhaDetail" :info="info"></buddha-detail>
        <a-space class="footer" v-if="info">
            <a-button :type="i % 2 == 0 ? 'default' : 'primary'" @click="operate(btn.type)" v-for="(btn, i) in btnList" :key="i">{{btn.title}}</a-button>
            <a-button type="danger" @click="delBuddhism">删除</a-button>
        </a-space>
        <buddhism-form class="buddhism-edit-area" :buddha="info.temp" :buddhism="info" :history="info.is_history == 1" @update="handleUpdate" @cancel="edit=false" v-if="edit"></buddhism-form>
        <a-modal
            :title="modalTitle"
            :maskClosable="false"
            :closable="false"
            :width="modalWidth"
            :footer="null"
            v-model="visible"
            v-if="info">
            <form-area
                ref="modalForm"
                class="flex-box vertical"
                layout="horizontal"
                form-item-row-type="flex"
                :label-col="{flex: '120px'}"
                :wrapper-col="{flex: 'auto'}"
                :items="items"
                :entity="form"
                confirm-text="提交"
                @cancel="cancelModal()"
                @confirm="confirmModal">
                <template v-slot:openUser="{form: config, control}">
                    <a-radio-group  v-model="config[control.key]">
                        <a-radio :value="b" v-for="b in openUserList" :key="b">
                            <open-data type="userName" :openid="b"/>
                        </a-radio>
                    </a-radio-group>
                </template>
            </form-area>
        </a-modal>
        <div class="buddha-tablet-back" v-show="tv" v-if="info" @click="tv=false">
            <tablet-print ref="tabletPrint" :list="info.tablet"></tablet-print>
            <a-icon type="close" class="buddha-tablet-close" />
        </div>
        <buddhism-registration ref="registration" :info="info" class="buddhism-registration"></buddhism-registration>
    </div>
</template>

<script>
    import {clone, printDom} from "../../common/js/tool";
    import * as buddhism from "../../common/buddha/buddhism";
    import BuddhaDetail from "../../layouts/BuddhaDetail";
    import {getLunarDay} from "../../components/calendar/lunar";
    import {isBuddhaAdmin} from "../../common/constant/org";
    import {getUserWxId} from "../../common/js/storage";
    import buddhismForm from "../../components/buddha/buddhism-form";
    import TabletPrint from "../../layouts/TabletPrint";
    import BuddhismRegistration from "../../layouts/buddha/BuddhismRegistrationInfo";

    export default {
        name: "BuddhismDetail",
        components: {
            BuddhaDetail,
            TabletPrint,
            BuddhismRegistration,
            buddhismForm
        },
        data() {
            return {
                form: {},
                admin: isBuddhaAdmin(),
                buddhism,
                info: null,
                visible: false,
                tv: false,
                edit: false,
                action: null,
                pd: 'hor' //预览纸张方向
            }
        },
        computed: {
            palaceList() {
                return this.$store.getters.palaceList || [];
            },
            btnList() {
                let res = [];
                let info = this.info;
                if(info) {
                    res = buddhism.getBuddhismOperateList(info);
                }
                return res;
            },
            items() {
                let res = [];
                let info = this.info;
                if(info) {
                    const action = this.action;
                    switch (action) {
                        case "open":
                        case "reOpen": {
                            res = clone(buddhism.openTabletItems)
                            const palaceList = this.palaceList;
                            // 殿堂列表
                            res[0].props.options = palaceList.map(item => {
                                return {
                                    key: item.id,
                                    title: item.name
                                }
                            });
                            res[0].listeners = {
                                change(val) {
                                    const con = this.controls.find(c => c.key == 'master');
                                    const palace = palaceList.find(o => o.id == val)?.name;
                                    this.$set(con.props, 'palace', palace);
                                }
                            }
                            let master = res.find(t => t.key == 'master');
                            if(master) {
                                master.props.info = info;
                            }
                            break;
                        }
                        case "dispatch":
                        case "reDispatch":
                            // 有开牌流程的派单 需指定开牌员
                            if(info.needOpen) {
                                res = [
                                    {
                                        key: 'kp_user_id',
                                        label: '开牌员',
                                        slot: "openUser",
                                        rules: [{ required: true, message: '请选择开牌员', trigger: 'change' }]
                                    },
                                ]
                            } else {
                                res = clone(buddhism.orderItems);
                                res[0].props.options = this.palaceList.map(item => {
                                    return {
                                        key: item.id,
                                        title: item.name
                                    }
                                });
                                res[1].props.info = info;
                            }
                            break;
                        case "confirmOrder":
                            res = [
                                {
                                    key: 'notice_type',
                                    label: '通知时间',
                                    component: 'a-radio-group',
                                    props: {
                                        options: [
                                            { label: '立即通知', value: 1 },
                                            { label: '定时通知', value: 2 },
                                        ]
                                    },
                                    listeners: {
                                        change(e) {
                                            let val = e.target.value;
                                            let i = this.controls.findIndex(c => c.key == 'notice_time');
                                            if(val == 2 && i == -1) {
                                                this.controls.splice(1, 0,
                                                  {
                                                      key: 'notice_time',
                                                      label: '定时通知时间',
                                                      component: 'a-date-picker',
                                                      props: {
                                                          placeholder: '请选择定时通知时间',
                                                          showTime: true,
                                                          format: 'YYYY-MM-DD HH:mm',
                                                          valueFormat: 'YYYY-MM-DD HH:mm',
                                                      },
                                                      rules: [{ required: true, message: '请选择定时通知时间', trigger: 'change' }]
                                                  }
                                                )
                                            } else if(i >= 0) {
                                                this.controls.splice(i ,1);
                                            }
                                        }
                                    },
                                    rules: [{ required: true, message: '请选择通知时间', trigger: 'change' }]
                                },
                                {
                                    key: 'note',
                                    label: '备注',
                                    component: 'a-textarea',
                                    props: {
                                        placeholder: "备注",
                                        rows: 4
                                    },
                                },
                            ]
                            break;
                        case "rejectOrder":
                            res = [
                                {
                                    key: 'reason',
                                    label: '驳回原因',
                                    component: 'a-textarea',
                                    props: {
                                        placeholder: "请输入驳回原因",
                                        rows: 4
                                    },
                                    rules: [{ required: true, message: '请输入驳回原因', trigger: 'blur' },]
                                }
                            ]
                            break;
                    }
                }
                return res;
            },
            id() {
                return this.$route.params.id;
            },
            modalWidth() {
                return  ['open', 'reOpen'].indexOf(this.action) >= 0 ? 1400 : 640;
            },
            modalTitle() {
                const action = this.action;
                let res = '';
                switch (action) {
                    case "open":
                        res = '开牌'
                        break;
                    case "reOpen":
                        res = '重新开牌'
                        break;
                    case "dispatch":
                        res = '派单'
                        break;
                    case "reDispatch":
                        res = '重新派单'
                        break;
                    case "confirmOrder":
                        res = '确认开牌单'
                        break;
                    case "rejectOrder":
                        res = '驳回开牌单'
                        break;
                }
                return res;
            },
            openUserList() {
                return this.$store.getters.openUserList || [];
            }
        },
        watch: {
            id() {
                if (this.$route.name !== 'BuddhismDetail') return;
                this.getDetail();
            },
            btnList(val) {
                if(val && val.findIndex(b => ['dispatch', 'reDispatch'].includes(b.type)) >= 0) {
                    this.$store.dispatch("getOpenUserList");
                }
            }
        },
        created() {
            this.$store.dispatch("getPalaceList");
            this.getDetail();
        },
        methods: {
            getLunarDay,
            handleUpdate() {
                this.getDetail();
                this.edit = false;
            },
            operate(type) {
                let fn = this[`${type}Buddhism`];
                if(typeof fn === 'function') {
                    fn();
                }
                this.action = type;
            },
            cancelBuddhism() {
                let info = this.info;
                let reason = "";
                this.$confirm({
                    title: '取消佛事',
                    content: <a-textarea placeholder="请输入取消原因" rows={4} onChange={e => reason = e.target.value} />,
                    onOk: () => {
                        if (!reason) {
                            this.$message.warning("请输入取消原因");
                            return Promise.reject();
                        }
                        let flow = info.flow || [];
                        return this.$axios({
                            url: `/admin/fs/cancel`,
                            method: 'POST',
                            data: {
                                id: info.id,
                                cancel_reason: reason,
                                flow:  JSON.stringify(flow.concat([
                                    {type: 99, title: '取消佛事', user_id: getUserWxId(), time: new Date().pattern("yyyy-MM-dd HH:mm:ss")}
                                ]))
                            },
                            noTempleFilter: true
                        }).then(() => {
                            this.getDetail();
                        })
                    }
                });
            },
            notifyRefundBuddhism() {
                let info = this.info;
                if(info.kp_user_id) {
                    let url = '/admin/fs-notice';
                    let content =  `【佛事已取消】佛事日期：${info.date} 佛事时间：${info.showTime} 佛事类型：${info.tempName}`;
                    if(info.evaluateUser) {
                        content += `经手人：${info.evaluateUser.fm || info.evaluateUser.name}`;
                    }
                    this.$axios({
                        url,
                        method: 'POST',
                        data: {
                            user_id: info.kp_user_id,
                            type: 2,
                            content,
                            fs_id: info.id
                        }
                    })
                } else {
                    this.$message.warning("未找到开牌员")
                }
            },
            editBuddhism(){
                this.edit = true;
            },
            previewTabletBuddhism() {
                this.tv = true;
            },
            printOrderBuddhism() {
                const tabletDom = this.$refs.buddhaDetail ? this.$refs.buddhaDetail.$refs.openTablet.$el : null;
                if(tabletDom) {
                    let style = document.createElement('style');
                    style.setAttribute("type", 'text/css');
                    style.setAttribute("meta", "print");
                    let css = '@page { margin: 0; padding: 0; border: 0; size: A4 portrait; }.tablet-seat-info{display: none;}';
                    style.appendChild(document.createTextNode(css));
                    const div = document.createElement("div");
                    div.setAttribute("style", "display:flex;align-items:center;justify-content:center;position:absolute;top:0;left:0;width:100%;height:100%;");
                    div.appendChild(tabletDom.cloneNode(true));
                    printDom(div, style);
                }
            },
            confirmOrderBuddhism() {
                this.showModal();
            },
            rejectOrderBuddhism() {
                this.showModal();
            },
            printTabletBuddhism() {
                const info = this.info;
                if (info.is_print === 1) {
                    this.$confirm({
                        title: '温馨提示',
                        content: '该牌位已打印，是否仍需打印？',
                        onOk: () => {
                            this.printTablet();
                        }
                    })
                } else {
                    this.printTablet();
                }
            },
            printTablet() {
                const info = this.info;
                let style = document.createElement('style');
                style.setAttribute("type", 'text/css');
                style.setAttribute("meta", "print");
                let css = '@page { margin: 0; padding: 0; border: 0; size: A4 landscape; }';
                style.appendChild(document.createTextNode(css));
                printDom(this.$refs.tabletPrint.$el, style, () => {
                    if (info.is_print == 0) {
                        this.$axios({
                            url: `/admin/fs/${info.id}`,
                            method: 'PATCH',
                            data: {
                                is_print: 1
                            }
                        }).then(res => {
                            this.$set(info, 'is_print', res.is_print);
                        })
                    }

                });
            },
            printInfoBuddhism() {
                let style = document.createElement('style');
                style.setAttribute("type", 'text/css');
                style.setAttribute("meta", "print");
                let css = '@page { size: A4 portrait; }';
                style.appendChild(document.createTextNode(css));
                printDom(this.$refs.registration.$el, style);
            },
            openBuddhism() {
                this.showModal();
            },
            reOpenBuddhism() {
                this.showModal();
            },
            dispatchBuddhism() {
                this.showModal();
            },
            reDispatchBuddhism() {
                this.showModal();
            },
            auditBuddhism() {
                let info = this.info;
                let flow = info.flow || [];
                let state = 1;
                const options = [
                  {label: '通过',value: 1},
                  {label: '不通过',value: 2},
                ]
                this.$confirm({
                    title: '牌位审核',
                    content: <div>
                        <buddha-tablet value={info.tablet} temp={info.temp} edit={false}></buddha-tablet>
                        <a-radio-group defaultValue={state} options={options} onChange={e => state = e.target.value}></a-radio-group>
                    </div>,
                    centered: true,
                    width: 480,
                    onOk: () => {
                        if(state == 1) {
                            this.updateBuddhism({
                                status: 4,
                                flow: JSON.stringify(flow.concat([
                                    {type: 2, title: '审核通过', user_id: getUserWxId(), time: new Date().pattern("yyyy-MM-dd HH:mm:ss")}
                                ]))
                            })
                        } else if(state == 2) {
                            this.updateBuddhism({
                                status: 3,
                                flow: JSON.stringify(flow.concat([
                                    {type: 2, title: '审核不通过', user_id: getUserWxId(), time: new Date().pattern("yyyy-MM-dd HH:mm:ss")}
                                ]))
                            })
                        }
                    },
                });
            },
            claimBuddhism() {
                this.$confirm({
                    title: '提示',
                    content: '确定认领吗？',
                    centered: true,
                    onOk: () => {
                        let wxId = getUserWxId();
                        this.updateBuddhism({
                            user_id: wxId,
                            status: this.info.admin_id ? 4 : 2, // 后台和小程序线下 提交不需要审核 直接设置状态为审核通过
                            flow: JSON.stringify([{type: 1, title: '已认领', user_id: wxId, time: new Date().pattern("yyyy-MM-dd HH:mm:ss")}])
                        }).then(() => {
                            this.$message.success("已认领");
                        });
                    },
                });
            },
            updateBuddhism(data, update = true) {
                let url = `/admin/fs/${this.info.id}`;
                return this.$axios({
                    url,
                    method: 'PATCH',
                    data
                }).then(() => {
                    update && this.getDetail();
                });
            },
            delBuddhism() {
                let info = this.info;
                this.$confirm({
                    title: '提示',
                    content: '删除之后数据不可恢复，确定删除吗？',
                    onOk: () => {
                        let url = `/admin/fs/${info.id}`;
                        this.$axios({
                            url,
                            method: 'DELETE',
                        }).then(() => {
                            this.$message.success("已删除");
                            this.$router.back();
                        });
                    },
                });
            },
            showModal() {
                let info = this.info;
                if(info) {
                    let form = {};
                    if(info.palace) {
                        form.palace = info.palace;
                    }
                    this.form = form;
                    this.visible = true;
                }
            },
            cancelModal() {
                this.visible = false;
                this.form = {};
            },
            confirmModal(form) {
                let flow = this.info.flow || [];
                const history = this.info.is_history == 1;
                let data = {};
                let wxId = getUserWxId();
                let flowItem = {user_id: wxId, time: new Date().pattern("yyyy-MM-dd HH:mm:ss")}
                const action = this.action;
                switch (action) {
                    case "open":
                    case "reOpen":
                        data = {
                            palace: form.palace
                        }
                        if(!history) {
                            data = {
                                ...data,
                                status: 10,
                                kp_user_id: wxId,
                                notice_type: form.notice_type,
                            }
                            if(form.notice_type == 2) {
                                data.notice_time = form.notice_time;
                            }
                            form.note && (data.note = form.note);
                            if(action == "reOpen") {
                                flowItem.title = '已重新开牌';
                                flowItem.type = 8;
                            } else {
                                flowItem.title = '已开牌';
                                flowItem.type = 5;
                            }
                            data.flow = JSON.stringify(flow.concat([flowItem]));
                        }
                        // 先更新佛事信息 再继续操作
                        this.updateBuddhism(data, false).then(() => {
                            this.openTablet(form.master);
                        })
                        break;
                    case "dispatch":
                    case "reDispatch":
                        // data.palace = form.palace
                        // 没有开牌流程的佛事需要选择殿堂和通知时间
                        if(!this.info.needOpen) {
                            data.status = 5;
                            data.notice_type = form.notice_type;
                            data.palace = form.palace;
                            if(form.notice_type == 2) {
                                data.notice_time = form.notice_time;
                            }
                            form.note && (data.note = form.note);
                        } else {
                            data.status = 9;
                            data.kp_user_id = form.kp_user_id;
                        }
                        if(action == "reDispatch") {
                            flowItem.title = '已重新派单';
                            flowItem.type = 9;
                        } else {
                            flowItem.title = '已派单';
                            flowItem.type = 4;
                        }
                        data.flow = JSON.stringify(flow.concat([flowItem]));
                        // 先更新佛事信息 再继续操作
                        this.updateBuddhism(data, false).then(() => {
                            if(!this.info.needOpen) {
                                this.openTablet(form.master);
                            } else {
                                this.getDetail();
                                this.visible = false;
                                this.$message.success("已派单");
                            }
                        })
                        break;
                    case "confirmOrder":
                        data = {
                            status: 11,
                            notice_type: form.notice_type,
                            flow: JSON.stringify((this.info.flow || []).concat([
                                {type: 6, title: '已确认', user_id: getUserWxId(), time: new Date().pattern("yyyy-MM-dd HH:mm:ss")}
                            ]))
                        }
                        if(form.notice_type == 2) {
                            data.notice_time = form.notice_time;
                        }
                        this.updateBuddhism(data).then(() => {
                            this.visible = false;
                            this.$message.success("已确认");
                        });
                        break;
                    case "rejectOrder":
                        data = {
                            status: 12,
                            flow: JSON.stringify((this.info.flow || []).concat([
                                {type: 7, title: '已驳回', user_id: getUserWxId(), time: new Date().pattern("yyyy-MM-dd HH:mm:ss"), reason: form.reason}
                            ]))
                        }
                        this.updateBuddhism(data).then(() => {
                            this.visible = false;
                            this.$message.success("已驳回");
                        });
                        break;
                }
            },
            openTablet(master) {
                let id = this.info.id;
                let list = [];
                master.forEach(item => {
                    let select = item.select;
                    if(select) {
                        select.forEach(m => {
                            let temp = {
                                fs_id: id,
                                name: m.name,
                                num: item.name,
                            }
                            m.mobile && (temp.mobile = m.mobile);
                            m.id && (temp.master_id = m.id);
                            item.ability && (temp.ability = item.ability);
                            list.push(temp);
                        })
                    }
                });
                let url = '/admin/fs-master/batch-insert';
                this.$axios({
                    url,
                    method: 'POST',
                    data: list
                }).then(res => {
                    if(res.error == 0) {
                        this.getDetail();
                        this.visible = false;
                        this.$message.success(this.info.needOpen ? "已开牌" : "已派单");
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            printTabletOrder() {
                let dom = this.$refs.buddhaDetail.$refs.openTablet.$el;
                printDom(dom);
            },
            // printTablet() {
            //     let dom = this.$refs.buddhaDetail.$refs.tablet.$el;
            //     printDom(dom);
            // },
            getDetail() {
                let id = this.id;
                if (!id) return;
                let url = `/admin/fs/${id}?expand=master,temp,order,tablet,evaluateUser,timeRange,palaceInfo`;
                this.$axios(url).then(this.dealInfo);
            },
            dealInfo(info) {
                buddhism.dealBuddhism(info);
                this.info = info;
            },
        }
    }
</script>

<style scoped lang="less">
    .buddhism-detail {
        position: relative;
        padding-bottom: 0;
    }
    .buddha-detail-area {
        flex: 1;
        min-height: 0;
        overflow: auto;
    }
    .buddha-tablet-back {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: @black;
        z-index: 100;
        overflow: auto;
    }
    .buddha-tablet-close {
        position: absolute;
        top: 16px;
        right: 16px;
        color: @white;
        font-size: 32px;
        cursor: pointer;
    }
    .buddhism-registration {
        display: none;
    }
    .buddhism-edit-area {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 16px;
        background-color: @component-background;
    }
</style>
