<template>
  <div class="niche-overview">
    <div class="piece-box niche-overview-top">
      <div
        class="niche-overview-item"
        :class="{ link: d.route }"
        v-for="(d, i) in overviewData"
        :key="i"
        @click="clickItem(d)"
      >
        <div class="niche-overview-title">
          <span>{{ d.title }}</span>
          <a-popover v-if="d.tip">
            <template slot="content">
              <span style="color: #666; cursor: default;">{{ d.tip }}</span>
            </template>
            <a-icon type="question-circle" class="tip-icon" />
          </a-popover>
        </div>
        <div class="niche-overview-num">{{ d.num }}</div>
      </div>
    </div>
    <div class="piece-box niche-select-area">
      <OverviewContent class="content-wrapper" :area-list="areaList" @select="selectDistrict" v-show="step == 1" />
      <div class="content-wrapper flex-box vertical" v-show="step == 2">
        <div class="data-title">
          <div class="title-left flex-box">
            <div class="niche-eg-item">
              <div class="niche-eg-icon"></div>
              <div class="niche-eg-title">不可选</div>
            </div>
            <div class="niche-eg-item">
              <div class="niche-eg-icon"></div>
              <div class="niche-eg-title">可选</div>
            </div>
            <div class="niche-eg-item">
              <!-- <div class="niche-eg-icon"></div> -->
              <div class="niche-eg-title" style="margin-left: 20px;">供奉情况：</div>
            </div>
            <div class="niche-eg-item">
              <!-- <div class="niche-eg-icon"></div> -->
              <div class="niche-eg-title">已供（{{ available }}）</div>
            </div>
            <div class="niche-eg-item">
              <!-- <div class="niche-eg-icon"></div> -->
              <div class="niche-eg-title">可供（{{ provided }}）</div>
            </div>
            <a-button type="link" class="back-btn" @click="back" v-show="step == 2">返回上级</a-button>
          </div>
          <a-form-model class="query-form" layout="inline">
            <a-form-model-item label="区域">
              <a-select style="width: 80px" placeholder="选择区域筛选" :options="areaList" v-model="district_id">
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="龛位编号" v-if="showSearch">
              <remote-search
                allow-clear
                v-model="num"
                url="/admin/mini-worship-buddhist-niche"
                query="&expand=worshipStatus"
                name-key="num"
                placeholder="输入龛位编号搜索"
                style="width:180px">
                <template v-slot:title="{ option }">
                  {{ getDistrictName(option.district_id) }} {{ option.num }}
                </template>
              </remote-search>
            </a-form-model-item>
          </a-form-model>
        </div>
        <SelectArea
          ref="selectArea"
          class="content-wrapper"
          area-open
          disabled
          :loading="loading"
          :list="list"
          :block-list="blockList"
          v-model="select"
        />
      </div>
      <!-- <niche-select show-search :select="select" @change="handleSelect" class="flex-grow" style="min-height: 0"></niche-select> -->
      <div class="niche-select-bottom" v-if="select">
        <div class="flex-box align-center">
          <span>已选</span>
          <div class="niche-select-info">
            <span>{{ getDistrictName(select.district) }} {{ select.num }}</span>
            <a-icon type="close" class="niche-select-remove" @click="removeSelect" />
          </div>
          <div class="niche-select-state">{{ select.statusText }}</div>
        </div>
        <a-space>
          <a-button @click="goNicheDetail">供奉信息</a-button>
          <a-button type="primary" @click="goGoods" :disabled="goodsDisabled" v-if="select && select.worshipStatus">
            供奉物品
          </a-button>
          <a-button type="primary" @click="goLight" :disabled="select.disabled">供奉</a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
// import nicheSelect from "./niche-select";
import {dealAreaList, dealNiche, dealNicheList, dispatchNicheLight} from "./light";
import OverviewContent from "./components/OverviewContent.vue";
import SelectArea from "./components/SelectArea.vue";
import { getUserWxId } from "../../common/js/storage";

export default {
  name: "NicheOverview",
  components: {
    // nicheSelect,
    OverviewContent,
    SelectArea,
  },
  data() {
    return {
      overviewData: [
        { title: "龛位总数", num: 4032 },
        { title: "累计供奉次数", num: 0, route: { name: 'NicheList', query: { status: 1 } } },
        { title: "即将到期", num: 0, tip: "统计倒计时还有三天的订单", route: { name: 'NicheOrder' } },
        { title: "今日查询次数", num: 0 },
        // { title: "今日共修次数", num: 0 },
      ],
      select: null,
      loading: true,
      step: 1,
      areaList: [],
      showSearch: true, // 是否可查询
      district_id: undefined, // 选中的区域
      num: undefined, // 查询的编号
      // provided: 0, // 可供
      // available: 0, // 已供

      goodsDisabled: false, // 供奉物品是否开启

      list: null,
      originList: [],
      hasGetParams: false,
    };
  },
  computed: {
    // list() {
    //   let target = this.areaList.find((area) => area.id == this.district_id);
    //   return target?.list || [];
    // },
    // originList() {
    //   let target = this.areaList.find((area) => area.id == this.district_id);
    //   return target?.buddhistNiche || [];
    // },
    blockList() {
      let target = this.areaList.find((area) => area.id == this.district_id);
      return target?.area || [];
    },
    available() {
      return this.originList.filter((item) => item.district_id == this.district_id && item.worshipStatus).length;
    },
    provided() {
      return this.originList.filter((item) => item.district_id == this.district_id && !item.disabled).length;
    },
  },
  watch: {
    district_id() {
      this.getBuddhistNiche();
    },
    select(val, old) {
      // 取消之前选择的龛位点亮
      if (old && !old.worshipStatus) {
        dispatchNicheLight(old, 2).finally(() => val && !val.worshipStatus && dispatchNicheLight(val, 1));
      } else {
        val && !val.worshipStatus && dispatchNicheLight(val, 1);
      }
    },
    num(val) {
      if (val) {
        dealNiche(val);
        this.select = val;
      }
    }
  },
  beforeRouteLeave(form, to, next) {
    const select = this.select;
    select && !select.worshipStatus && dispatchNicheLight(select, 2);
    next();
  },
  async created() {
    this.getCount();
    this.getArea();
    this.getConf();
  },
  methods: {
    getBuddhistNiche() {
      let id = this.district_id;
      if (!id) {
        return;
      }
      this.loading = true;
      this.list = null;
      this.originList = [];
      return this.$axios({
        url: "/admin/mini-worship-buddhist-niche",
        method: "GET",
        params: {
          page: 1,
          pageSize: 9999,
          "filter[district_id]": id,
          expand: "worshipStatus,worshipType",
        },
        noTempleFilter: true,
      }).then((res) => {
        let list = res.data;
        this.list = dealNicheList(list);
        this.originList = list;

        const params = this.$route.params;
        if (!this.hasGetParams && params.id) {
          this.hasGetParams = true;
          let target = this.originList.find((item) => item.id == params.id);
          this.$set(this, "select", target);
          this.step = 2;
        }
      }).finally(() => this.loading = false);
    },
    getArea() {
      return this.$axios({
        url: "/admin/mini-worship-district",
        method: "GET",
        params: {
          expand: "area.buddhistNiche,nicheCount",
        },
        noTempleFilter: true,
      }).then((res) => {
        let areaList = res.data;
        dealAreaList(areaList);
        this.areaList = areaList;
        this.setParamsSelect();
      });
    },
    setParamsSelect() {
      const params = this.$route.params;
      if (params.id) {
        this.district_id = params.district;
      }
    },
    getConf() {
      this.$axios({
        url: "/admin/mini-worship-item/get-config",
        method: "GET",
        params: {},
      }).then((res) => {
        if (res.error == 0) {
          let data = res.data;
          if (data) {
            if (data.open) {
              // 启用
              let user_ids = data.user_ids;
              if (user_ids?.length) {
                let user_id = getUserWxId();
                if (!user_ids.includes(user_id)) {
                  this.goodsDisabled = true;
                }
              }
            } else {
              this.goodsDisabled = true;
            }
          }
        }
      });
    },
    goNicheDetail() {
      const select = this.select;
      if (select) {
        this.$router.push({
          name: "NicheDetail",
          params: { id: select.id, district: select.district },
        });
      }
    },
    goLight() {
      const select = this.select;
      if (select) {
        const district = this.areaList.find((item) => item.id == select.district_id);
        this.$store.commit("setNiche", {...select, district});
        this.$router.push({
          name: "NicheOrderCreate",
          query: { select: select.id },
        });
      }
    },
    goGoods() {
      const select = this.select;
      if (select?.worshipStatus) {
        this.$router.push({
          name: "NicheWorshipGoods",
          params: { id: select.worshipStatus.id },
          query: { id: select.id, district: select.district, num: select.num },
        });
      }
    },
    getDistrictName(district_id) {
      const d = this.areaList.find(a => a.id == district_id);
      return d?.name || "";
    },
    handleSelect(select) {
      this.select = select;
    },
    removeSelect() {
      this.select = null;
    },
    getCount() {
      const url = "/admin/mini-worship-order/count";
      this.$axios(url).then((res) => {
        const overviewData = this.overviewData;
        overviewData[0].num = res[0];
        overviewData[1].num = res[1];
        overviewData[2].num = res[4];
        overviewData[3].num = res[2];
        // overviewData[4].num = res[3];
      });
    },
    selectDistrict(id) {
      this.step = 2;
      let target = this.areaList.find((item) => item.id == id);
      this.district_id = target.id;
    },
    // 获取供奉信息
    getWorshipInfo({ list = [], statusList = [] }) {
      let total = list.reduce((acc, val) => acc + val.length, 0);
      this.provided = total - statusList.filter((item) => item.status == 2).length; // 可供
      this.available = statusList.filter((item) => item.can_goods).length; // 已供
    },
    back() {
      this.step = 1;
      this.select = null;
    },
    clickItem(d) {
      let route = d.route;
      if (route) {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style lang="less">
.niche-overview {
  flex: none;
  display: flex;
  flex-direction: column;
  margin: @padding-md auto;
  width: 1200px;
}
.niche-overview-top {
  display: flex;
  padding: 20px;
}
.niche-overview-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: default;
  &.link {
    cursor: pointer;
  }
}
.niche-overview-title {
  font-size: 20px;
  font-weight: 600;
  .tip-icon {
    color: #ff4d4f;
    margin-left: 4px;
    cursor: pointer;
  }
}
.niche-overview-num {
  margin-top: 8px;
  font-size: 16px;
  color: @text-color-secondary;
}
.niche-select-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  min-height: 0;
}

.niche-select-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 60px;
  border-top: 1px solid rgba(255, 140, 0, 0.1);
  color: #ff8c00;
  .niche-select-info {
    display: flex;
    align-items: center;
    margin-left: 16px;
    padding: 6px 12px;
    height: 32px;
    border-radius: 4px;
    background-color: #ff8c00;
    color: @white;
    line-height: 1;
    font-size: 16px;
  }
  .niche-select-remove {
    margin-left: 8px;
    color: @white;
    cursor: pointer;
  }
  .niche-select-state {
    margin-left: 8px;
    color: @text-color-secondary;
  }
}
.content-wrapper {
  flex: 1;
  min-height: 0;
}
.data-title {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 140, 0, 0.1);
  .niche-eg-item {
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-right: 16px;
      .niche-eg-icon {
        opacity: 0.5;
      }
    }
  }
  .niche-eg-icon {
    width: 20px;
    height: 22px;
    background: url(../../assets/light/niche.png) center / contain no-repeat;
  }
  .niche-eg-title {
    margin-left: 0.5em;
  }
}
.back-btn {
  margin-left: 24px;
}
</style>
